<template>
  <div class="control__btn-wrapper">
    <Button
      v-if="buttons.includes('createReport')"
      transparent
      @click.native="$emit('createReport')"
      >{{ $t('buttons.save_draft') }}
    </Button>
    <Button
      v-if="buttons.includes('saveAsDraft')"
      transparent
      @click.native="$emit('saveAsDraft')"
      >{{ $t('buttons.save_draft') }}
    </Button>
    <Button
      v-if="buttons.includes('signAndSend')"
      :disabled="disabledSign"
      icon-name="arrow"
      id="sign_and_send"
      isRightIcon
      @click.native="$emit('signAndSend')"
      >{{ $t('buttons.sign_and_send') }}
    </Button>
    <Button
      v-if="buttons.includes('makeImprovement')"
      id="sign_and_send"
      icon-name="arrow"
      transparent
      isRightIcon
      @click.native="$emit('signAndSend')"
      >{{ $t('buttons.make_improvement') }}
    </Button>
    <Button
      v-if="buttons.includes('createAndSignReport')"
      icon-name="arrow"
      id="sign_and_send"
      isRightIcon
      @click.native="$emit('createAndSignReport')"
      >{{ $t('buttons.sign_and_send') }}
    </Button>
    <Button
      v-if="buttons.includes('sendForImprovement')"
      transparent
      :disabled="disabled"
      @click.native="$emit('sendForImprovement')"
      >{{ $t('buttons.send_for_improvement') }}
    </Button>
    <Button
      v-if="buttons.includes('sendToComission')"
      transparent
      @click.native="$emit('sendToComission')"
      >{{ $t('buttons.send_to_comission') }}
    </Button>
    <Button
      v-if="buttons.includes('leaveUnpended')"
      :is-icon="true"
      @click.native="$emit('leaveUnpended')"
      >{{ $t('buttons.leave_unpended') }}
    </Button>
    <Button
      v-if="buttons.includes('sendToComissionHead')"
      :is-icon="true"
      @click.native="$emit('sendToComissionHead')"
      >{{ $t('buttons.send_to_comission_head') }}
    </Button>
    <Button
      v-if="buttons.includes('preliminaryResults')"
      transparent
      :is-icon="true"
      @click.native="$emit('preliminaryResults')"
      >{{ $t('buttons.preliminary_results') }}
    </Button>
    <Button
      v-if="buttons.includes('vote')"
      transparent
      :disabled="disabledVote"
      :is-icon="true"
      @click.native="$emit('vote')"
      >{{ $t('buttons.voting') }}
    </Button>
    <Button v-if="buttons.includes('voteDisabled')" transparent disabled
      >{{ $t('buttons.voting') }}
    </Button>
    <Button
      v-if="buttons.includes('print')"
      icon-name="print"
      isRightIcon
      @click.native="$emit('print')"
      >{{ $t('buttons.print') }}
    </Button>
    <Button
      v-if="buttons.includes('downloadCertificate')"
      :disabled="disabledDownLoad"
      @click.native="$emit('downloadCertificate')"
      >{{ $t('buttons.download_certificate') }}
    </Button>
    <Button
      v-if="buttons.includes('changeDate')"
      @click.native="$emit('changeDate')"
      >{{ $t('buttons.change_meeting_date') }}
    </Button>
    <Button
      v-if="buttons.includes('approveReport')"
      @click.native="$emit('approveReport')"
      >{{ $t('buttons.approve_report') }}
    </Button>
    <Button
      v-if="buttons.includes('downloadPDF')"
      :disabled="disabledAchive"
      @click.native="$emit('downloadPDF')"
      >{{ $t('buttons.download_pdf') }}
    </Button>
  </div>
</template>

<script>
export default {
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledSign: {
      type: Boolean,
      default: false,
    },
    disabledVote: {
      type: Boolean,
      default: false,
    },
    disabledDownLoad: {
      type: Boolean,
      default: false,
    },
    disabledAchive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    createReport() {
      this.dataToSend = new FormData();
      this.gatherAllCriteriasCreate('draft');
      this.$store.dispatch('addCategorizationRequestDraft', this.dataToSend);
    },
  },
};
</script>
<style lang="sass">
.control__btn-wrapper
  justify-content: end
  display: flex
  gap: 4rem
  flex-wrap: wrap
  @include s
    gap: 2rem
  @include xs
    flex-direction: column
    align-items: center
</style>
